<template>
  <div>
  </div>
</template>

<script>
export default {
  name: "EndIndex",
  data() {
    return {};
  },

  mounted() {
    this.query();
  },

  methods: {
    query() {}
  }
};
</script>

<style lang="less" scoped>
</style>